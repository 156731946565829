import $ from 'jquery';
import _ from 'underscore';
import Marionette from 'backbone.marionette';
import i18next from 'i18next';
import jqueryI18next from 'jquery-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { supportedLanguages } from './i18const';

// Subscribe to the 'render' event globally
// Extend the Marionette.View prototype
Marionette.View.prototype.render = _.wrap(
  Marionette.View.prototype.render,
  function render(render) {
    // Call the original render method
    if (this._isDestroyed) {
      return this;
    }

    this.triggerMethod('before:render', this);

    // If this is not the first render call, then we need to
    // re-initialize the `el` for each region
    if (this._isRendered) {
      this._reInitRegions();
    }

    this._renderTemplate();
    this.bindUIElements();

    if (this.$el.localize) {
      this.$el.localize();
    }

    this._isRendered = true;
    this.triggerMethod('render', this);

    return this;
  }
);

export async function initi18() {
  await i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      debug: DEVMODE,
      load: 'languageOnly',
      preload: ['en'],
      supportedLngs: supportedLanguages,
      fallbackLng: 'en',
      ns: ['common', 'auth', 'geoman', 'cookieConsent'],
    });

  jqueryI18next.init(i18next, $);

  // set html lang attribute
  $('html').attr('lang', i18next.resolvedLanguage);

  if (DEVMODE) {
    window.i18next = i18next;
  }
}

export function _t(...args) {
  return i18next.t(...args);
}

export const i18 = i18next;
